import { put, takeLeading, all, select } from "redux-saga/effects"
import { setPatient, setRelatives, updateProspect } from "../client/actions"
import { CURRENT_CONSULTATIONS_PAGE, IS_THIS_YOUR_ACCOUNT, RETRIEVE_ACCOUNT, SURVEY_PAGE, VERIFY_PHONE } from "../../core/constants"
import { push } from "redux-first-history";

import { addError } from "../verify/actions"

import {
  getLoginData,
  getProspectNir,
} from "../client/selector"
import config from "react-global-configuration"
import { languages } from "../../lib/languages"
import { VALIDATION_STEP } from "../../types/contants"
import { doctorListRequest } from "../doctor/actions"
import {
  Consultation,
  CustomerPersonnalData,
  LoginData,
  LoginInfos,
  ResponseLogin,
} from "../../types/types"
import { Payload, createAccountPayload } from "../../types/payload"

import {
  CREATE_ACCOUNT,
  RETRIEVE_ACCOUNT_BY_NAME_AND_PHONE,
  RETRIEVE_ACCOUNT_BY_NAME_AND_NIR,
  RETRIEVE_ACCOUNT_BY_BIRTHDATE_AND_NIR,
  RETRIEVE_ACCOUNT_BY_BIRTHDATE_AND_PHONE,
} from "./constants"
import { handleGenericApiError } from "../../lib/api-errors"
import {
  createAccountSuccess,
  createAccountError,
  loginSuccess,
  retrieveAccountError,
} from "./actions"
import { Customer } from "types/new/types/entity";
import Types from "pages/currentConsultations/services/constants";
import { request } from "lib/request";

interface AccountByNamePayload {
  phone?: string
  prefixPhone?: string
  nir?: string
  lastname: string
}

function checkIfPersonalDataAreSet({
  firstname = "",
  lastname = "",
  birthdate = "",
  gender = "",
  nir = "",
  email = "",
}: CustomerPersonnalData) {
  return (
    email !== "" &&
    firstname !== "" &&
    lastname !== "" &&
    birthdate !== "" &&
    gender !== "" &&
    nir == ""
  )
}

function checkIfLoginDataAreSet({ phone = "" }: LoginInfos) {
  return phone !== ""
}

function formatPayload(payload: Customer) {
  const { insee_code, birth_country, birth_location, ...rest } = payload;
  return {
    ...rest,
    birth_location: insee_code && insee_code.toString() || "99999",
    firstname: rest.firstname ? rest.firstname : rest.first_birth_firstname,
    lastname: rest.lastname ? rest.lastname : rest.birth_lastname,
  }
}

function* signupRequest(
  payloadToSend: createAccountPayload
): Generator<Promise<Payload> | unknown, Promise<Payload> | unknown> {
  const createAccountUrl = config.get("clinic.routes.signup.default")

  const formattedPayload = formatPayload(payloadToSend as any);

  const fromNir = yield select(({ client }) => client.prospect?.fromNir);


  return yield fetch(`api${createAccountUrl}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-User-Token": sessionStorage.getItem("X-USER-Token") || "",
    },
    body: JSON.stringify({ ...formattedPayload, fromNir }),
  })
    .then(handleGenericApiError)
    .then((res) => {
      const userToken = res.headers.get("x-patient-token")
      const statusCode = res.status
      return res.json().then((body: Object) => ({
        body,
        headers: { "x-patient-token": userToken },
        statusCode,
      }))
    })
    .catch((error) => {
      throw error
    })
}

function* handleSignUpResponse(signUpResponse: Payload) {
  if (signUpResponse && signUpResponse.statusCode === 200) {
    // Provisory x-patient-token for phone validation
    yield all([
      put(
        updateProspect({
          "x-patient-token": signUpResponse.headers["x-patient-token"],
        })
      ),
      put(doctorListRequest()),
    ])

    /**
     * TODO : redirect to verify phone
     */

    yield put(
      push(VERIFY_PHONE, { step: VALIDATION_STEP.VERIFY_PHONE })
    )
  } else {
    throw languages.createAccountError
  }
}

// specify any otherwise takeLatest does'nt find the type
function* createAccount({ payload }: any) {
  let payloadToSend = Object.assign(payload)

  if (!payload.nir) {
    const nir: string | undefined = yield select(getProspectNir)
    payloadToSend = { ...payloadToSend, nir }
  }
  if (!checkIfLoginDataAreSet(payloadToSend)) {
    const LoginDataInStore: LoginData = yield select(getLoginData)
    payloadToSend = { ...payloadToSend, ...LoginDataInStore }
  }
  try {
    const signUpResponse: Payload = yield signupRequest(payloadToSend)
    yield handleSignUpResponse(signUpResponse)
    yield put(createAccountSuccess())
  } catch (error) {
    const currentPath: string = yield select(
      (state) => state.router.location.pathname
    )
    yield put(createAccountError())
    yield put(
      addError({
        path: currentPath,
        error: { type: "error", text: languages.createAccountError },
      })
    )
  }
}

function* storeCustomerFlow(response: Payload, consultations: Consultation[]) {
  const patientToken = response.headers["x-patient-token"]
  localStorage.setItem("patientToken", patientToken)
  let account = response.body
  if (typeof response.body === "string") {
    account = JSON.parse(response.body)
  }

  yield all([
    put(loginSuccess(account as ResponseLogin)),
    put(setPatient({ ...account.customer, token: patientToken })),
    put(setRelatives(account.relatives)),
    put({ type: Types.SET_CONSULTATIONS, payload: consultations }),
    put(doctorListRequest()),
  ])
}

/////////////////////////////////////////////////////////////
// RETRIEVE ACCOUNT BY 4 WAYs
/////////////////////////////////////////////////////////////
type RetrieveAccount =
  | { phone: string; brithdate: string }
  | { nir: string; brithdate: string }
  | { phone: string; lastname: string }
  | { nir: string; lastname: string }
/////////////////////////////////////////////////////////////
async function retrieveAccountApi(url: string, payload: RetrieveAccount) {
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-User-Token": sessionStorage.getItem("X-USER-Token") || "",
    },
    body: JSON.stringify(payload),
  })
    .then(handleGenericApiError)
    .then((res) => {
      const userToken = res.headers.get("x-patient-token")
      const statusCode = res.status
      return res.json().then((body: Object) => ({
        body,
        headers: { "x-patient-token": userToken },
        statusCode,
      }))
    })
    .catch((error) => {
      // TODO : yield put LastName search Error
      throw error
    })
}

export async function getConsultations(token: string) {
  try {
    const response = await request(
      `/public/clinic/v1/customers/consultations`,
      { method: "GET", additionalHeaders: { "X-PATIENT-Token": token } }
    )
    return (response as any).consultations
  } catch (e) {
    throw e
  }
}

/////////////////////////////////////////////////////////////
function* handleVerificationResponse(response: Payload, type: string) {
  if (response.statusCode === 200) {
    const consultations: Consultation[] = yield getConsultations(response.headers["x-patient-token"])
    yield storeCustomerFlow(response, consultations)
    // Check if NIR is from the main patient (exact===true) or from a relative of the main patient (exact===false)
    // Ignore "exact" field if account retrieved by phone
    if (response.body.exact || [RETRIEVE_ACCOUNT_BY_NAME_AND_PHONE, RETRIEVE_ACCOUNT_BY_BIRTHDATE_AND_PHONE].includes(type)) {
      if (consultations?.length) {
        yield put(push(CURRENT_CONSULTATIONS_PAGE))
      } else {
        yield put(push(SURVEY_PAGE))
      }
    } else {
      yield put(push(IS_THIS_YOUR_ACCOUNT))
    }
  } else if (response.statusCode === 404) {
    yield put(retrieveAccountError(languages.account_not_found))
    yield put(push(RETRIEVE_ACCOUNT))
  } else {
    throw languages.defaultErrorForm
  }
}

/////////////////////////////////////////////////////////////
function* retrieveAccount({
  payload,
  type,
}: {
  payload: RetrieveAccount
  type: string
}) {
  let url = "/api"
  switch (type) {
    case RETRIEVE_ACCOUNT_BY_BIRTHDATE_AND_PHONE:
      url += config.get("clinic.routes.authentication.by_phone.with_birthdate")
      break
    case RETRIEVE_ACCOUNT_BY_NAME_AND_NIR:
      url += config.get("clinic.routes.authentication.by_nir.with_lastname")
      break
    case RETRIEVE_ACCOUNT_BY_BIRTHDATE_AND_NIR:
      url += config.get("clinic.routes.authentication.by_nir.with_birthdate")
      break
    case RETRIEVE_ACCOUNT_BY_NAME_AND_PHONE:
      url += config.get("clinic.routes.authentication.by_phone.with_lastname")
      break
    default:
      break
  }
  try {
    const response: Payload = yield retrieveAccountApi(url, payload)
    yield handleVerificationResponse(response, type)
  } catch (error) {
    yield put(retrieveAccountError(languages.createAccountError))
  }
}

/////////////////////////////////////////////////////////////
function* registerWatcher() {
  yield takeLeading([CREATE_ACCOUNT], createAccount)
  yield takeLeading(
    [
      RETRIEVE_ACCOUNT_BY_NAME_AND_NIR,
      RETRIEVE_ACCOUNT_BY_NAME_AND_PHONE,
      RETRIEVE_ACCOUNT_BY_BIRTHDATE_AND_NIR,
      RETRIEVE_ACCOUNT_BY_BIRTHDATE_AND_PHONE,
    ],
    retrieveAccount
  )
}

export default registerWatcher
