import React from "react"
import { withCard, withHeaderAndFooter } from "../../components/hocs"
import { connect } from "react-redux"
import DateInput from "../../components/generics/DateInput/DateInput"
import { Button, Form, Input } from "antd"
import { buttonLayout } from "../../styles/constants"
import { languages } from "../../lib/languages"
import { authActions } from "../../services/authentication/actions"
import { dateValidator } from "../../lib/form"
import { DATE_FORMAT, formatDateToYYYYMMDD } from "../../lib/utils"
import dayjs from "dayjs"
import { AuthenticationStore } from "../../services/authentication/reducer"
import { Navigate, useNavigate } from "react-router-dom"
import { HOME_PAGE } from "../../core/constants"
import PreviousButton from "../../components/new/Previous/PreviousButton"
import TextTransition from "react-text-transition"
import { Card } from "../../components/new/Card/Card"
import { FormPage } from "../../components/new/Form/Form"
import icon_info from "../../assets/icons/info_icon.svg";
import styles from "./Auth.module.scss";


export interface AuthType {
  type: "PHONE" | "NIR" | "UNKNOWN"
  value: string
}

export type ByType = AuthType

export interface WithType {
  type: "BIRTHDATE" | "LASTNAME"
  value: string
}

export enum AUTH_STEP {
  BIRTHDATE = "BIRTHDATE",
  LASTNAME = "LASTNAME",
  AUTH_FAILED = "AUTH_FAILED",
}

export interface AuthProps extends AuthenticationStore {
  submit: (byType: ByType, withType: WithType) => void
  logout: () => void
}

const BirthdateForm: React.FC<{ disabled: boolean }> = ({ disabled }) => {
  return (
    <Form.Item
      label={languages.birthdate}
      name="birthdate"
      rules={
        disabled
          ? []
          : [
            {
              required: true,
              message: languages.inputYour + languages.birthdate,
            },
            dateValidator,
          ]
      }
    >
      <DateInput readOnly={disabled} />
    </Form.Item>
  )
}

const LastnameForm: React.FC<{ disabled: boolean }> = ({ disabled }) => {
  return (
    <Form.Item
      label={languages.lastname}
      name="lastname"
      rules={
        disabled
          ? []
          : [
            {
              required: true,
              message: languages.inputYour + languages.lastname,
            },
          ]
      }
    >
      <Input readOnly={disabled} />
    </Form.Item>
  )
}

const FormButton: React.FC<AuthProps> = (props: AuthProps) => {
  const navigate = useNavigate()
  if (props.step === AUTH_STEP.AUTH_FAILED)
    return (
      <div className="wrapped-buttons space-around" style={{ marginTop: "30%" }}>
        <Button
          {...buttonLayout}
          htmlType="submit"
          type="primary"
          onClick={() => {
            props.logout()
            navigate(HOME_PAGE)
          }}
        >
          {languages.backToHome}
        </Button>
      </div>
    )
  else
    return (
      <Form.Item>
        <div className="wrapped-buttons space-around">
          <Button
            {...buttonLayout}
            htmlType="submit"
            type="primary"
            loading={props.loading}
            disabled={props.loading}
          >
            {languages.continue}
          </Button>
        </div>
      </Form.Item>
    )
}

const getTitleStep = (step: AUTH_STEP) => {
  switch (step) {
    case AUTH_STEP.BIRTHDATE:
      return languages.authent_birthdate
    case AUTH_STEP.LASTNAME:
      return languages.authent_lastname
    case AUTH_STEP.AUTH_FAILED:
    default:
      return languages.authent_failed
  }
}

const Index: React.FC<AuthProps> = (props: AuthProps) => {
  const navigate = useNavigate()
  if (props.authType.type === "UNKNOWN") return <Navigate to={HOME_PAGE} replace />
  const handleSubmit = ({
    lastname,
    birthdate,
  }: {
    lastname: string
    birthdate: string
  }) => {
    if (props.step === AUTH_STEP.BIRTHDATE) {
      const formattedBirthdate = formatDateToYYYYMMDD(birthdate)
      const value = dayjs(formattedBirthdate).format(DATE_FORMAT)
      props.submit(props.authType, { type: "BIRTHDATE", value })
    } else if (props.step === AUTH_STEP.LASTNAME)
      props.submit(props.authType, { type: "LASTNAME", value: lastname })
  }

  const title = getTitleStep(props.step)
  const classTitle = "title-card"

  const birthdateFormDisabled = ![AUTH_STEP.BIRTHDATE].includes(props.step)

  const input = birthdateFormDisabled
    ? { name: "lastname", required: true, label: title }
    : { name: "birthdate", required: true, label: title }

  return [AUTH_STEP.AUTH_FAILED].includes(props.step) ? (
    <div className="card-contenant" style={{ marginTop: "10%" }}>
      <PreviousButton text={languages.cancel} reset={true} />
      <h1 className={classTitle} style={{ position: "fixed", left: 0, right: 0, top: "25%" }}>
        <TextTransition
          className="align-center"
          text={title}
          springConfig={{ mass: 1, tension: 1000, friction: 100 }}
        />
      </h1>
      <div className={styles.warningMsg} style={{ position: "fixed", top: "45%", left: 0, right: 0, textAlign: "center" }}>
        <img src={icon_info} alt="" width="40px" style={{ marginRight: ".25em" }} /><span>{languages.authent_failed_hint}</span>
      </div>
      <FormButton {...props} />
    </div>
  ) : (
    <Card subtitle={languages.accountFound}>
      <FormPage
        key={input.label}
        inputs={[input]}
        onFinish={handleSubmit as any}
        cancelText={languages.logout}
        onCancel={() => {
          props.logout()
          navigate(HOME_PAGE)
        }}
        acceptButton={languages.continue}
      />
    </Card>
  )
}

const mapStateToProps = ({
  authentication,
}: {
  authentication: Partial<AuthProps>
}): Partial<AuthProps> => {
  return authentication
}

const mapDispatchToProps = (dispatch: Function): Partial<AuthProps> => {
  return {
    submit: (_by: ByType, _with: WithType) => {
      dispatch(authActions.submit(_by, _with))
    },
    logout: () => dispatch({ type: "RESET_STORE" }),
  }
}

const AuthPage = withHeaderAndFooter(withCard(Index))
export default connect(mapStateToProps, mapDispatchToProps)(AuthPage as any)
